<!-- datos personales -->
<template>
  <form @submit.prevent="$emit('submitForm')">
    <div v-if="tabs == 1">
      <div class="p-3">
        <div class="row pt-3">
          <div class="col-md-6">
            <label for="nameAndLastName" class="form-label"
              >Nombres y Apellidos</label
            >
            <input
              disabled
              :value="persona.nombres + ' ' + persona.apellidos"
              placeholder="Nombres y Apellidos"
              type="text"
              class="form-control"
              id="nameAndLastName"
            />
          </div>
          <div class="col-md-6">
            <label for="phoneFijo" class="form-label">Teléfono Fijo</label>
            <input
              disabled
              required
              v-model="persona.telefonoFijo"
              placeholder="Teléfono Fijo"
              type="text"
              class="form-control"
              id="phoneFijo"
            />
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-6">
            <label for="typeDoc" class="form-label">Tipo Documento</label>
            <b-form-select
              disabled
              v-model="persona.tipoDocumento.id"
              value-field="id"
              text-field="descripcion"
              :options="listaTipoDocumento"
            ></b-form-select>
          </div>
          <div class="col-md-6">
            <label for="phoneCell" class="form-label">Teléfono Celular</label>
            <input
              disabled
              required
              v-model="persona.telefonoMovil"
              placeholder="nombre"
              type="text"
              class="form-control"
              id="phoneCell"
            />
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-6">
            <label for="numberDoc" class="form-label">Número Documento</label>
            <input
              disabled
              v-model="persona.numeroDocumento"
              placeholder="Número Documento"
              type="text"
              class="form-control"
              id="numberDoc"
            />
          </div>
          <div class="col-md-6">
            <label for="categoryLic" class="form-label"
              >Categoría Licencia (Opcional)</label
            >
            <b-form-select
              v-model="informacionPersonal.licenseCategoryId"
              value-field="id"
              text-field="description"
              :options="listaCategoriaLicencia"
            ></b-form-select>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-6">
            <label for="addressDom" class="form-label"
              >Dirección de Domicilio</label
            >
            <input
              disabled
              required
              v-model="persona.direccion"
              placeholder="Dirección de Domicilio"
              type="text"
              class="form-control"
              id="addressDom"
            />
          </div>
          <div class="col-md-6">
            <label for="driveLic" class="form-label"
              >Licencia Conducción (Opcional)</label
            >
            <input
              v-model="informacionPersonal.licenseNumber"
              placeholder="Licencia Conducción"
              type="text"
              class="form-control"
              id="driveLic"
            />
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-6">
            <label for="barrio" class="form-label">Barrio</label>
            <input
              required
              v-model="informacionPersonal.neighborhood"
              placeholder="Barrio"
              type="text"
              class="form-control"
              id="barrio"
            />
          </div>
          <div class="col-md-6">
            <label for="contactEmer" class="form-label"
              >Contacto Caso Emergencia</label
            >
            <input
              required
              v-model="informacionPersonal.emergencyContact"
              placeholder="Contacto Caso Emergencia"
              type="text"
              class="form-control"
              id="contactEmer"
            />
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-6">
            <label for="locality" class="form-label">Localidad</label>
            <input
              required
              v-model="informacionPersonal.locality"
              placeholder="Localidad"
              type="text"
              class="form-control"
              id="locality"
            />
          </div>
          <div class="col-md-6">
            <label for="cellEmer" class="form-label">Teléfono Emergencia</label>
            <input
              required
              v-model="informacionPersonal.emergencyContactNumber"
              placeholder="Teléfono Emergencia"
              type="text"
              class="form-control"
              id="cellEmer"
            />
          </div>
          <div class="col-md-6 pt-3">
            <label for="status" class="form-label">Estrato</label>
            <input
              required
              v-model="informacionPersonal.stratum"
              placeholder="Estrato"
              type="text"
              class="form-control"
              id="status"
            />
          </div>
          <div
            class="col-md-6"
            style="
              padding-top: 2.2rem;
              display: flex;
              align-items: center;
              justify-content: end;
            "
          >
            <vs-button type="submit"> Guardar </vs-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Number,
      required: true,
    },
    persona: {
      type: Object,
      required: true,
    },
    informacionPersonal: {
      type: Object,
      required: true,
    },
    listaTipoDocumento: {
      type: Array,
      required: true,
    },
    listaCategoriaLicencia: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
