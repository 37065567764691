import { render, staticRenderFns } from "./tab_informacion_familiar.vue?vue&type=template&id=fa82cecc&scoped=true"
import script from "./tab_informacion_familiar.vue?vue&type=script&lang=js"
export * from "./tab_informacion_familiar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa82cecc",
  null
  
)

export default component.exports