import Swal from "sweetalert2";

export default {
  completeDataInfoPerson() {
    this.informacionPersonal.fullName =
      this.persona.nombres + " " + this.persona.apellidos;
    this.informacionPersonal.documentTypeId = this.persona.tipoDocumento.id;
    this.informacionPersonal.identificationNumber =
      this.persona.numeroDocumento;
    this.informacionPersonal.phoneNumber = this.persona.telefonoFijo;
    this.informacionPersonal.cellPhoneNumber = this.persona.telefonoMovil;
    this.informacionPersonal.residenceAddress = this.persona.direccion;
    this.informacionPersonal.personaId = this.persona.id;
    this.informacionPersonal.empresaId = this.persona.empresa.id;
  },
  closeCreate() {
    this.isCreated = false;
    this.newEducacionFormal = {};
  },
  closeCreateInformal() {
    this.isCreatedInformal = false;
    this.newEducacionInformal = {};
  },
  closeCreateProfessionalExperience() {
    this.isCreatedProfessionalExperience = false;
    this.newExperienciaProfesional = {};
  },
  closeCreateFamilyInformation() {
    this.isCreatedFamilyInformation = false;
    this.newFamilyInformation = {};
  },
  closeCreatePersonalReferences() {
    this.isCreatedPersonalReferences = false;
    this.newPersonalReferences = {};
  },
  initDataInfoEducationFormal() {
    let newData = {
      academicLevelId: null,
      countryId: null,
      degree: null,
      departamentId: null,
      educationalInstitution: null,
      empresaID: null,
      endDate: null,
      municipalityId: null,
      personaId: null,
    };

    this.isCreated = true;
    this.newEducacionFormal = newData;
  },
  initDataInfoEducationInformal() {
    let newData = {
      courseName: null,
      educationalInstitution: null,
      trainingHours: null,
      departamentId: null,
      personaId: null,
      empresaID: null,
      endDate: null,
      municipalityId: null,
      countryId: null,
    };

    this.isCreatedInformal = true;
    this.newEducacionInformal = newData;
  },
  initDataInfoProfessionalExperience() {
    let newData = {
      companyName: null,
      startDate: null,
      endDate: null,
      monthsWorked: null,
      retirementReason: null,
      positionHeld: null,
      inmediateBoss: null,
      phoneNumber: null,
      personaId: null,
      empresaID: null,
      countryId: null,
      municipalityId: null,
      departamentId: null,
    };

    this.isCreatedProfessionalExperience = true;
    this.newExperienciaProfesional = newData;
  },
  initDataInfoFamilyInformation() {
    let newData = {
      relationshipId: null,
      fullName: null,
      identificationNumber: null,
      birthDay: null,
      occupation: null,
      personaId: null,
      empresaID: null,
      countryId: null,
      municipalityId: null,
      departamentId: null,
    };

    this.isCreatedFamilyInformation = true;
    this.newFamilyInformation = newData;
  },
  initDataInfoPersonalReferences() {
    let newData = {
      fullName: null,
      occupation: null,
      phoneNumbar: null,
      residenceAddress: null,
      personaId: null,
      empresaID: null,
      countryId: null,
      municipalityId: null,
      departamentId: null,
    };

    this.isCreatedPersonalReferences = true;
    this.newPersonalReferences = newData;
  },
  async createPersonalInformation(data) {
    console.log(data);
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "PersonalInformation/Create/",
        data,
      });
      console.log("PersonalSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
      // if (res.length > 0) {
      //   this.listaMedallas = res;
      // } else {
      //   this.listaMedallas = [];
      // }
    } catch (error) {
      // this.listaMedallas = [];
      console.log("err", error);
      Swal.fire(
        "Error!",
        "Ha ocurrido un error al guardar los datos.",
        "error"
      );
    }
  },
  async updatePersonalInformation(data) {
    console.log(data);
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "PersonalInformation/Update/",
        data,
      });
      console.log("PersonalSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
      // if (res.length > 0) {
      //   this.listaMedallas = res;
      // } else {
      //   this.listaMedallas = [];
      // }
    } catch (error) {
      // this.listaMedallas = [];
      console.log("err", error);
      Swal.fire(
        "Error!",
        "Ha ocurrido un error al guardar los datos.",
        "error"
      );
    }
  },
  async sendUpdateInformalEducation() {
    let obj = this.filtrarPorId(this.educacionNoFormal, this.idItem);

    const newObj = Object.keys(obj).reduce((acc, key) => {
      if (key !== "DepartamentosPais" && key !== "MunicipioDepartamento") {
        acc[key] = obj[key];
      }
      return acc;
    }, {});

    this.updateInformalEducation(newObj);
    // console.log("aloneEducationInformal", newObj);
  },
  async sendUpdateProfessionalExperience() {
    let obj = this.filtrarPorId(this.experienciaProfesional, this.idItem);

    const newObj = Object.keys(obj).reduce((acc, key) => {
      if (key !== "DepartamentosPais" && key !== "MunicipioDepartamento") {
        acc[key] = obj[key];
      }
      return acc;
    }, {});

    this.updateProfessionalExperience(newObj);
    // console.log("aloneProfessionalExperience", newObj);
  },
  async sendUpdateFormalEducation() {
    let obj = this.filtrarPorId(this.educacionFormal, this.idItem);

    const newObj = Object.keys(obj).reduce((acc, key) => {
      if (key !== "DepartamentosPais" && key !== "MunicipioDepartamento") {
        acc[key] = obj[key];
      }
      return acc;
    }, {});

    this.updateFormalEducation(newObj);
    console.log("aloneEducationFormal", newObj);
  },
  async sendUpdateFamilyInformation() {
    let obj = this.filtrarPorId(this.informacionFamiliar, this.idItem);

    const newObj = Object.keys(obj).reduce((acc, key) => {
      if (key !== "DepartamentosPais" && key !== "MunicipioDepartamento") {
        acc[key] = obj[key];
      }
      return acc;
    }, {});

    this.updateFamilyInformation(newObj);
    // console.log("aloneFamilyInformation", newObj);
  },
  async sendUpdatePersonalReferences() {
    let obj = this.filtrarPorId(this.referenciasPersonales, this.idItem);

    const newObj = Object.keys(obj).reduce((acc, key) => {
      if (key !== "DepartamentosPais" && key !== "MunicipioDepartamento") {
        acc[key] = obj[key];
      }
      return acc;
    }, {});

    this.updatePersonalReferences(newObj);
    // console.log("alonePersonalReferences", newObj);
  },
  getIdForData(id) {
    this.idItem = id;
  },
  filtrarPorId(arrayData, id) {
    return arrayData.find((objeto) => objeto.id === id);
  },
  async updatePersonalReferences(data) {
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "PersonalReferences/Update/",
        data,
      });
      console.log("PersonalReferencesSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
    } catch (error) {
      console.log("err", error);
      Swal.fire(
        "Error!",
        "Ha ocurrido un error al guardar los datos.",
        "error"
      );
    }
  },
  async updateFamilyInformation(data) {
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "FamilyInformation/Update/",
        data,
      });
      console.log("FamilyInformationSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
    } catch (error) {
      console.log("err", error);
      Swal.fire(
        "Error!",
        "Ha ocurrido un error al guardar los datos.",
        "error"
      );
    }
  },
  async updateProfessionalExperience(data) {
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "ProfessionalExperience/Update/",
        data,
      });
      console.log("ProfesionalExperienceSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
    } catch (error) {
      console.log("err", error);
      Swal.fire(
        "Error!",
        "Ha ocurrido un error al guardar los datos.",
        "error"
      );
    }
  },
  async updateInformalEducation(data) {
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "InformalEducation/Update/",
        data,
      });
      console.log("InformalEducationSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
    } catch (error) {
      console.log("err", error);
      Swal.fire(
        "Error!",
        "Ha ocurrido un error al guardar los datos.",
        "error"
      );
    }
  },
  async updateFormalEducation(data) {
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "FormalEducation/Update/",
        data,
      });
      console.log("FormalEducationSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
    } catch (error) {
      console.log("err", error);
      Swal.fire(
        "Error!",
        "Ha ocurrido un error al guardar los datos.",
        "error"
      );
    }
  },
  async createFamilyInformation() {
    this.newFamilyInformation.personaId = this.persona.id;
    this.newFamilyInformation.empresaID = this.persona.empresa.id;
    console.log("createProfessionalExperience", this.newFamilyInformation);
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "FamilyInformation/Create/",
        data: this.newFamilyInformation,
      });
      console.log("FamilyInformationSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
      this.closeCreateFamilyInformation();
      await this.getvalidateExistence();
      this.$refs.addNewFamily.click(); // Simular clic en el botón
      this.closeCreateFamilyInformation();
    } catch (error) {
      console.log("err", error);
      // Swal.fire(
      //   "Error!",
      //   "Ha ocurrido un error al guardar los datos.",
      //   "error"
      // );
    }
  },
  async createPersonalReferences() {
    this.newPersonalReferences.personaId = this.persona.id;
    this.newPersonalReferences.empresaID = this.persona.empresa.id;
    console.log("createProfessionalExperience", this.newPersonalReferences);
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "PersonalReferences/Create/",
        data: this.newPersonalReferences,
      });
      console.log("PersonalReferencesSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
      this.closeCreatePersonalReferences();
      await this.getvalidateExistence();
      this.$refs.addNewPersonalReferences.click(); // Simular clic en el botón
      this.closeCreatePersonalReferences();
    } catch (error) {
      console.log("err", error);
      // Swal.fire(
      //   "Error!",
      //   "Ha ocurrido un error al guardar los datos.",
      //   "error"
      // );
    }
  },
  async createProfessionalExperience() {
    this.newExperienciaProfesional.personaId = this.persona.id;
    this.newExperienciaProfesional.empresaID = this.persona.empresa.id;
    console.log("createProfessionalExperience", this.newExperienciaProfesional);
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "ProfessionalExperience/Create/",
        data: this.newExperienciaProfesional,
      });
      console.log("ProfessionalExperienceSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
      this.closeCreateProfessionalExperience();
      await this.getvalidateExistence();
      this.$refs.addNewProfessionalExperience.click(); // Simular clic en el botón
      this.closeCreateProfessionalExperience();
    } catch (error) {
      console.log("err", error);
      // Swal.fire(
      //   "Error!",
      //   "Ha ocurrido un error al guardar los datos.",
      //   "error"
      // );
    }
  },
  async createInformalEducation() {
    this.newEducacionInformal.personaId = this.persona.id;
    this.newEducacionInformal.empresaID = this.persona.empresa.id;
    console.log("createInformalEducation", this.newEducacionInformal);
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "InformalEducation/Create/",
        data: this.newEducacionInformal,
      });
      console.log("InformalEducationSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
      this.closeCreateInformal();
      await this.getvalidateExistence();
      this.$refs.addNewInformal.click(); // Simular clic en el botón
      this.closeCreateInformal();
    } catch (error) {
      console.log("err", error);
      // Swal.fire(
      //   "Error!",
      //   "Ha ocurrido un error al guardar los datos.",
      //   "error"
      // );
    }
  },
  async createFormalEducation() {
    this.newEducacionFormal.personaId = this.persona.id;
    this.newEducacionFormal.empresaID = this.persona.empresa.id;
    console.log("createFormalEducation", this.newEducacionFormal);
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "FormalEducation/Create/",
        data: this.newEducacionFormal,
      });
      console.log("FormalEducationSaved... ", res);
      Swal.fire("Listo!", "Se han guardado los datos.", "success");
      this.closeCreate();
      await this.getvalidateExistence();
      this.$refs.addNewFormal.click(); // Simular clic en el botón
      this.closeCreate();
    } catch (error) {
      console.log("err", error);
      // Swal.fire(
      //   "Error!",
      //   "Ha ocurrido un error al guardar los datos.",
      //   "error"
      // );
    }
  },
  async updateDataPersona(data) {
    // console.log("newPersona", data);

    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "Persona/Update/",
        data,
      });
      console.log("InfoPersonaUpdate... ", res);
      // if (res.length > 0) {
      //   this.listaMedallas = res;
      // } else {
      //   this.listaMedallas = [];
      // }
    } catch (error) {
      // this.listaMedallas = [];
      console.log("err", error);
    }
  },
  async sendDataPersonalInformation() {
    if (!this.validateExistence.personalInformacion) {
      this.completeDataInfoPerson();
      // await this.updateDataPersona(this.persona)
      await this.createPersonalInformation(this.informacionPersonal);
      await this.getvalidateExistence();
      // console.log("tabs_DatosPersonales", 1, this.informacionPersonal);
    } else {
      // await this.updateDataPersona(this.persona)
      await this.updatePersonalInformation(this.informacionPersonal);
      await this.getvalidateExistence();
    }
  },
  async actualizaMunicipios(item, e, tipo) {
    //console.log(item)
    //console.log(e.target.value)
    //console.log(id)

    // create

    if (tipo == "newPersonalReferences") {
      this.dataLocation.municipios = await this.getMunicipioByDepartamento(
        e.target.value
      );
    }
    if (tipo == "newFamilyInformation") {
      this.dataLocation.municipios = await this.getMunicipioByDepartamento(
        e.target.value
      );
    }
    if (tipo == "newExperienciaProfesional") {
      this.dataLocation.municipios = await this.getMunicipioByDepartamento(
        e.target.value
      );
    }
    if (tipo == "newEducacionFormal") {
      this.dataLocation.municipios = await this.getMunicipioByDepartamento(
        e.target.value
      );
    }
    if (tipo == "newEducacionInformal") {
      this.dataLocation.municipios = await this.getMunicipioByDepartamento(
        e.target.value
      );
    }

    // existentes
    if (tipo == "itemEducacionFormal") {
      let id = this.educacionFormal.indexOf(item);
      this.educacionFormal[id].MunicipioDepartamento = [];
      this.educacionFormal[id].MunicipioDepartamento =
        await this.getMunicipioByDepartamento(e.target.value);
    }
    if (tipo == "itemEducacionNoFormal") {
      let id = this.educacionNoFormal.indexOf(item);
      this.educacionNoFormal[id].MunicipioDepartamento = [];
      this.educacionNoFormal[id].MunicipioDepartamento =
        await this.getMunicipioByDepartamento(e.target.value);
    }
    if (tipo == "itemExperienciaProfesional") {
      let id = this.experienciaProfesional.indexOf(item);
      this.experienciaProfesional[id].MunicipioDepartamento = [];
      this.experienciaProfesional[id].MunicipioDepartamento =
        await this.getMunicipioByDepartamento(e.target.value);
    }

    if (tipo == "itemFamilyinformation") {
      let id = this.informacionFamiliar.indexOf(item);
      this.informacionFamiliar[id].MunicipioDepartamento = [];
      this.informacionFamiliar[id].MunicipioDepartamento =
        await this.getMunicipioByDepartamento(e.target.value);
    }

    if (tipo == "itemReferenciaPersonal") {
      let id = this.referenciasPersonales.indexOf(item);
      this.referenciasPersonales[id].MunicipioDepartamento = [];
      this.referenciasPersonales[id].MunicipioDepartamento =
        await this.getMunicipioByDepartamento(e.target.value);
    }
    this.$forceUpdate();
  },
  async getvalidateExistence() {
    try {
      // personalInformation
      let personalInformacion = await this.$store.dispatch("hl_get", {
        path:
          "PersonalInformation/GetByPersonaId/" +
          this.persona.id +
          "/" +
          this.persona.empresa.id,
      });
      if (personalInformacion.id) {
        this.validateExistence.personalInformacion = personalInformacion;
        this.informacionPersonal = personalInformacion;
        // console.log(
        //   "getvalidateExistence personalInformacion------>",
        //   personalInformacion
        // );
      } else {
        this.validateExistence.personalInformacion = null;
      }

      // formalEducation
      let formalEducation = await this.$store.dispatch("hl_get", {
        path:
          "FormalEducation/GetByPersonaId/" +
          this.persona.id +
          "/" +
          this.persona.empresa.id,
      });
      if (formalEducation.length) {
        this.validateExistence.formalEducation = formalEducation;
        this.educacionFormal = formalEducation;
        this.isCreated = false;
        // console.log(
        //   "getvalidateExistence formalEducation------>",
        //   formalEducation
        // );
      } else {
        this.validateExistence.educacionFormal = null;
        this.educacionFormal = [];
        this.isCreated = true;
        if (!this.validateExistence.educacionFormal) {
          this.initDataInfoEducationFormal();
          // console.log(this.educacionFormal);
        }
      }

      // informalEducation
      let informalEducation = await this.$store.dispatch("hl_get", {
        path:
          "InformalEducation/GetByPersonaId/" +
          this.persona.id +
          "/" +
          this.persona.empresa.id,
      });
      if (informalEducation.length) {
        this.validateExistence.informalEducation = informalEducation;
        this.educacionNoFormal = informalEducation;
        this.isCreatedInformal = false;
        // console.log(
        //   "getvalidateExistence informalEducation------>",
        //   informalEducation
        // );
      } else {
        this.validateExistence.educacionNoFormal = null;
        this.educacionNoFormal = [];
        this.isCreatedInformal = true;
        if (!this.validateExistence.educacionNoFormal) {
          this.initDataInfoEducationInformal();
          // console.log(this.educacionNoFormal);
        }
      }

      // professionalExperience

      let professionalExperience = await this.$store.dispatch("hl_get", {
        path:
          "ProfessionalExperience/GetByPersonaId/" +
          this.persona.id +
          "/" +
          this.persona.empresa.id,
      });
      if (professionalExperience.length) {
        this.validateExistence.professionalExperience = professionalExperience;
        this.experienciaProfesional = professionalExperience;
        this.isCreatedProfessionalExperience = false;
        // console.log(
        //   "getvalidateExistence professionalExperience------>",
        //   professionalExperience
        // );
      } else {
        this.validateExistence.professionalExperience = null;
        this.experienciaProfesional = [];
        this.isCreatedProfessionalExperience = true;
        if (!this.validateExistence.professionalExperience) {
          this.initDataInfoProfessionalExperience();
          // console.log(this.experienciaProfesional);
        }
      }

      // familyInformation

      let familyInformation = await this.$store.dispatch("hl_get", {
        path:
          "FamilyInformation/GetByPersonaId/" +
          this.persona.id +
          "/" +
          this.persona.empresa.id,
      });
      if (familyInformation.length) {
        this.validateExistence.familyInformation = familyInformation;
        this.informacionFamiliar = familyInformation;
        this.isCreatedFamilyInformation = false;
        // console.log(
        //   "getvalidateExistence familyInformation------>",
        //   familyInformation
        // );
      } else {
        this.validateExistence.familyInformation = null;
        this.informacionFamiliar = [];
        this.isCreatedFamilyInformation = true;
        if (!this.validateExistence.familyInformation) {
          this.initDataInfoFamilyInformation();
          // console.log(this.informacionFamiliar);
        }
      }

      // personalReferences

      let personalReferences = await this.$store.dispatch("hl_get", {
        path:
          "PersonalReferences/GetByPersonaId/" +
          this.persona.id +
          "/" +
          this.persona.empresa.id,
      });
      if (personalReferences.length) {
        this.validateExistence.personalReferences = personalReferences;
        this.referenciasPersonales = personalReferences;
        this.isCreatedPersonalReferences = false;
        // console.log(
        //   "getvalidateExistence personalReferences------>",
        //   personalReferences
        // );
      } else {
        this.validateExistence.personalReferences = null;
        this.referenciasPersonales = [];
        this.isCreatedPersonalReferences = true;
        if (!this.validateExistence.personalReferences) {
          this.initDataInfoPersonalReferences();
          // console.log(this.referenciasPersonales);
        }
      }

      // extras
      await this.getNivelesAcademicos();
      await this.getPaisList();

      // formalEducation
      for await (let itemDepto of this.educacionFormal) {
        itemDepto.DepartamentosPais = await this.getDepartmentoPaisList(
          itemDepto.countryId
        );
        itemDepto.MunicipioDepartamento = await this.getMunicipioByDepartamento(
          itemDepto.departamentId
        );
      }
      // informalEducation
      for await (let itemDepto of this.educacionNoFormal) {
        itemDepto.DepartamentosPais = await this.getDepartmentoPaisList(
          itemDepto.countryId
        );
        itemDepto.MunicipioDepartamento = await this.getMunicipioByDepartamento(
          itemDepto.departamentId
        );
      }

      // professionalExperience
      for await (let itemDepto of this.experienciaProfesional) {
        itemDepto.DepartamentosPais = await this.getDepartmentoPaisList(
          itemDepto.countryId
        );
        itemDepto.MunicipioDepartamento = await this.getMunicipioByDepartamento(
          itemDepto.departamentId
        );
      }

      // familyInformation
      for await (let itemDepto of this.informacionFamiliar) {
        itemDepto.DepartamentosPais = await this.getDepartmentoPaisList(
          itemDepto.countryId
        );
        itemDepto.MunicipioDepartamento = await this.getMunicipioByDepartamento(
          itemDepto.departamentId
        );
      }

      // personalReferences
      for await (let itemDepto of this.referenciasPersonales) {
        itemDepto.DepartamentosPais = await this.getDepartmentoPaisList(
          itemDepto.countryId
        );
        itemDepto.MunicipioDepartamento = await this.getMunicipioByDepartamento(
          itemDepto.departamentId
        );
      }
    } catch (error) {
      this.validateExistence = [];
      console.log("err", error);
    }
  },
  async getTipoDocumento() {
    try {
      let res = await this.$store.dispatch("hl_post", {
        path: "TipoDocumento/ListTipoDocumento",
      });
      // console.log("------>", res);
      if (res.length > 0) {
        this.listaTipoDocumento = res;
        ////console.log(res)
      } else {
        this.listaTipoDocumento = [];
      }
    } catch (error) {
      this.listaTipoDocumento = [];
      console.log("err", error);
    }
  },
  async getNivelesAcademicos() {
    try {
      let res = await this.$store.dispatch("hl_get", {
        path: "ValuesCatalog/GetListByCodeCatalog/NivelesAcademicos",
      });
      console.log("niveles academicos", res);
      if (res.length > 0) {
        this.NivelesAcademicos = res;
        ////console.log(res)
      } else {
        this.NivelesAcademicos = [];
      }
    } catch (error) {
      this.NivelesAcademicos = [];
      console.log("err", error);
    }
  },
  async getParentesco() {
    try {
      let res = await this.$store.dispatch("hl_get", {
        path: "ValuesCatalog/GetListByCodeCatalog/Parentesco",
      });
      // console.log("parentesco", res);
      if (res.length > 0) {
        this.Parentesco = res;
        // console.log(res)
      } else {
        this.Parentesco = [];
      }
    } catch (error) {
      this.Parentesco = [];
      console.log("err", error);
    }
  },
  async getPaisList() {
    try {
      let res = await this.$store.dispatch("hl_post", { path: "Pais/List" });
      // console.log("------>", res);
      if (res.length > 0) {
        this.listaPaises = res;
        ////console.log(res)
      } else {
        this.listaPaises = [];
      }
    } catch (error) {
      this.listaPaises = [];
      console.log("err", error);
    }
  },
  async getMunicipioByDepartamento(id) {
    try {
      let res = await this.$store.dispatch("hl_get", {
        path: "Municipio/ListByDepartamento/" + id,
      });
      // console.log("------>", res);
      if (res.length > 0) {
        return res;
        ////console.log(res)
      } else {
        return [];
      }
    } catch (error) {
      console.log("err getDepartmentoPaisList", error);
      return [];
    }
  },
  async getDepartmentoPaisList(id) {
    try {
      let res = await this.$store.dispatch("hl_get", {
        path: "DepartamentoPais/ListByPais/" + id,
      });
      //console.log("------>", res);
      if (res.length > 0) {
        this.dataLocation.departamentos = res;
        return res;
        ////console.log(res)
      } else {
        return [];
      }
    } catch (error) {
      console.log("err getDepartmentoPaisList", error);
      return [];
    }
  },
  async getcategoriaLicencia() {
    try {
      let res = await this.$store.dispatch("hl_get", {
        path: "ValuesCatalog/GetListByCodeCatalog/CategoriaLicencia",
      });
      //console.log("------>", res);
      if (res.length > 0) {
        this.listaCategoriaLicencia = res;
        ////console.log(res)
      } else {
        this.listaCategoriaLicencia = [];
      }
    } catch (error) {
      this.listaCategoriaLicencia = [];
      console.log("err", error);
    }
  },
  async getData() {
    this.$isLoading(true);
    try {
      let res = await this.$store.dispatch("hl_get", {
        path: "Persona/GetPersona/" + this.id,
      });
      console.log("PersonaData------>", res);
      this.$isLoading(false);
      if (res.id) {
        this.persona = res;
        ////console.log(res)
      } else {
        this.persona = {};
      }
    } catch (error) {
      this.$isLoading(false);
      this.persona = {};
      //console.log("err", error);
    }
  },
  async deleteItem(id, path) {
    Swal.fire({
      title: "Seguro eliminar registro?",
      text: "Después de eliminarlo no podrás recuperarlo!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        //let loader = this.$loading.show();
        await this.$store.dispatch("hl_get", {
          path: path + id,
        });
        Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        await this.getvalidateExistence();

        // formalEducation
        if (this.tabs == 2) {
          this.$refs.addNewFormal.click(); // Simular clic en el botón
          this.closeCreate();
        }
        if (!this.educacionFormal.length) {
          this.$refs.addNewFormal.click(); // Simular clic en el botón
        }

        // informalEducation
        if (this.tabs == 3) {
          this.$refs.addNewInformal.click(); // Simular clic en el botón
          this.closeCreateInformal();
        }
        if (!this.educacionNoFormal.length) {
          this.$refs.addNewInformal.click(); // Simular clic en el botón
        }

        // professionalExperience
        if (this.tabs == 4) {
          this.$refs.addNewProfessionalExperience.click(); // Simular clic en el botón
          this.closeCreateProfessionalExperience();
        }
        if (!this.experienciaProfesional.length) {
          this.$refs.addNewProfessionalExperience.click(); // Simular clic en el botón
        }

        // familyInformation
        if (this.tabs == 5) {
          this.$refs.addNewFamily.click(); // Simular clic en el botón
          this.closeCreateFamilyInformation();
        }
        if (!this.informacionFamiliar.length) {
          this.$refs.addNewFamily.click(); // Simular clic en el botón
        }

        // personalReferences
        if (this.tabs == 6) {
          this.$refs.addNewPersonalReferences.click(); // Simular clic en el botón
          this.closeCreatePersonalReferences();
        }
        if (!this.referenciasPersonales.length) {
          this.$refs.addNewPersonalReferences.click(); // Simular clic en el botón
        }
      }
    });
  },
};
