<!-- informacion familiar -->
<template>
  <div v-if="tabs == 5">
    <div class="p-3">
      <!-- existentes -->
      <form @submit.prevent="sendUpdateFamilyInformation">
        <div
          v-for="(itemFamilyinformation, enf1) of informacionFamiliar"
          :key="itemFamilyinformation.id"
        >
          <p class="text-center">Ref. Información Familiar {{ enf1 + 1 }}</p>
          <div class="d-flex justify-content-end">
            <vs-button
              danger
              @click="
                deleteItem(
                  itemFamilyinformation.id,
                  'FamilyInformation/Delete/'
                )
              "
            >
              <i class="fas fa-solid fa-trash pr-2"></i>Eliminar
            </vs-button>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Parentesco</label
              >
              <b-form-select
                required
                v-model="itemFamilyinformation.relationshipId"
                value-field="id"
                text-field="description"
                :options="Parentesco"
              ></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Nombres y Apellidos</label
              >
              <input
                required
                v-model="itemFamilyinformation.fullName"
                placeholder="Nombres y Apellidos"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Número Documento</label
              >
              <input
                required
                v-model="itemFamilyinformation.identificationNumber"
                placeholder="Ingrese documento"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Cumpleaños</label
              >
              <input
                required
                @input="itemFamilyinformation.birthDay = $event.target.value"
                :value="
                  $moment
                    .tz(itemFamilyinformation.birthDay, 'America/Costa_Rica')
                    .format('YYYY-MM-DD')
                "
                placeholder=""
                type="date"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Ocupación</label
              >
              <input
                required
                v-model="itemFamilyinformation.occupation"
                placeholder="Ingrese ocupación"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >País</label
              >
              <b-form-select
                required
                @change.native="
                  getDepartmentoPaisList(
                    itemFamilyinformation.countryId,
                    'itemFamilyinformation'
                  )
                "
                v-model="itemFamilyinformation.countryId"
                value-field="id"
                text-field="descripcion"
                :options="listaPaises"
              ></b-form-select>
            </div>
            <div class="col-md-6 pt-3">
              <label for="exampleFormControlInput1" class="form-label"
                >Municipio</label
              >
              <b-form-select
                v-model="itemFamilyinformation.municipalityId"
                required
                value-field="id"
                text-field="descripcion"
                :options="itemFamilyinformation.MunicipioDepartamento"
              ></b-form-select>
            </div>
            <div class="col-md-6 pt-3">
              <label for="exampleFormControlInput1" class="form-label"
                >Departamento</label
              >
              <b-form-select
                required
                v-model="itemFamilyinformation.departamentId"
                @change.native="
                  actualizaMunicipios(
                    itemFamilyinformation,
                    $event,
                    'itemFamilyinformation'
                  )
                "
                value-field="id"
                text-field="descripcion"
                :options="itemFamilyinformation.DepartamentosPais"
              ></b-form-select>
            </div>
          </div>
          <button
            type="submit"
            @click="getIdForData(itemFamilyinformation.id)"
            class="mx-1 my-4 btn px-4 py-2 btn-primary"
          >
            Guardar
          </button>
        </div>
      </form>

      <!-- nuevos -->
      <form @submit.prevent="createFamilyInformation">
        <div v-show="isCreatedFamilyInformation">
          <p class="text-center">
            <b-badge class="mx-1" variant="success">Nueva</b-badge>
            Información Familiar
          </p>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Parentesco</label
              >
              <b-form-select
                required
                v-model="newFamilyInformation.relationshipId"
                value-field="id"
                text-field="description"
                :options="Parentesco"
              ></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Nombres y Apellidos</label
              >
              <input
                required
                v-model="newFamilyInformation.fullName"
                placeholder="Nombres y Apellidos"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Número Documento</label
              >
              <input
                required
                v-model="newFamilyInformation.identificationNumber"
                placeholder="Ingrese documento"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Cumpleaños</label
              >
              <input
                required
                v-model="newFamilyInformation.birthDay"
                placeholder=""
                type="date"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Ocupación</label
              >
              <input
                required
                v-model="newFamilyInformation.occupation"
                placeholder="Ingrese ocupación"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >País</label
              >
              <b-form-select
                required
                @change.native="
                  getDepartmentoPaisList(
                    newFamilyInformation.countryId,
                    'newFamilyInformation'
                  )
                "
                v-model="newFamilyInformation.countryId"
                value-field="id"
                text-field="descripcion"
                :options="listaPaises"
              ></b-form-select>
            </div>
            <div class="col-md-6 pt-3">
              <label for="exampleFormControlInput1" class="form-label"
                >Municipio</label
              >
              <b-form-select
                required
                v-model="newFamilyInformation.municipalityId"
                value-field="id"
                text-field="descripcion"
                :options="dataLocation.municipios"
              ></b-form-select>
            </div>
            <div class="col-md-6 pt-3">
              <label for="exampleFormControlInput1" class="form-label"
                >Departamento</label
              >
              <b-form-select
                required
                v-model="newFamilyInformation.departamentId"
                value-field="id"
                @change.native="
                  actualizaMunicipios(
                    newFamilyInformation,
                    $event,
                    'newFamilyInformation'
                  )
                "
                text-field="descripcion"
                :options="dataLocation.departamentos"
              ></b-form-select>
            </div>
            <div class="d-flex pt-2">
              <vs-button type="submit">
                Guardar
              </vs-button>
              <vs-button
                danger
                @click="closeCreateFamilyInformation"
              >
                Cancelar
              </vs-button>
            </div>
          </div>
        </div>
      </form>
      <div
        class="col-xl-12"
        style="display: flex; align-items: center; justify-content: end"
      >
        <vs-button
          ref="addNewProfessionalExperience"
          v-show="!isCreatedFamilyInformation"
          success
          @click="initDataInfoFamilyInformation"
        >
          Agregar Nueva
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Number,
      required: true,
    },
    informacionFamiliar: {
      type: Array,
      required: true,
    },
    newFamilyInformation: {
      type: Object,
      required: true,
    },
    listaPaises: {
      type: Array,
      required: true,
    },
    Parentesco: {
      type: Array,
      required: true,
    },
    dataLocation: {
      type: Object,
      required: true,
    },
    isCreatedFamilyInformation: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    sendUpdateFamilyInformation() {
      this.$emit("sendUpdateFamilyInformation", this.informacionFamiliar);
    },
    deleteItem(itemId, endpoint) {
      this.$emit("deleteItem", itemId, endpoint);
    },
    createFamilyInformation() {
      this.$emit("createFamilyInformation", this.newFamilyInformation);
    },
    getIdForData(id) {
      this.$emit("getIdForData", id);
    },
    getDepartmentoPaisList(countryId, itemKey) {
      this.$emit("getDepartmentoPaisList", countryId, itemKey);
    },
    actualizaMunicipios(item, event, itemKey) {
      this.$emit("actualizaMunicipios", item, event, itemKey);
    },
    initDataInfoFamilyInformation() {
      this.$emit("initDataInfoFamilyInformation");
    },
    closeCreateFamilyInformation() {
      this.$emit("closeCreateFamilyInformation");
    },
  },
};
</script>

<style scoped></style>
