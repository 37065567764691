<!-- educacion formal -->
<template>
  <div v-if="tabs == 2">
    <div class="p-3">
      <!-- existentes -->
      <form @submit.prevent="sendUpdateFormalEducation">
        <div
          v-for="(itemEducacionFormal, ef1) of educacionFormal"
          :key="itemEducacionFormal.id"
        >
          <p class="text-center">Ref. Educación Formal {{ ef1 + 1 }}</p>
          <div class="d-flex justify-content-end">
            <span
              class="pointer btn btn-outline-danger m-1"
              @click="
                deleteItem(itemEducacionFormal.id, 'FormalEducation/Delete/')
              "
            >
              <i class="fas fa-solid fa-trash px-1"></i>Eliminar
            </span>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Nivel Académico</label
              >
              <!-- <input required v-model="item.nombre" placeholder="Nombres y Apellidos" type="text" class="form-control" id="exampleFormControlInput1" /> -->
              <b-form-select
                v-model="itemEducacionFormal.academicLevelId"
                value-field="id"
                text-field="description"
                :options="NivelesAcademicos"
              ></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Institución Educativa</label
              >
              <input
                required
                v-model="itemEducacionFormal.educationalInstitution"
                placeholder=""
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Título Obtenido</label
              >
              <input
                required
                v-model="itemEducacionFormal.degree"
                placeholder="ej: Developer"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Fecha</label
              >
              <input
                @input="itemEducacionFormal.endDate = $event.target.value"
                :value="
                  $moment
                    .tz(itemEducacionFormal.endDate, 'America/Costa_Rica')
                    .format('YYYY-MM-DD')
                "
                placeholder="nombre"
                type="date"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >País</label
              >
              <b-form-select
                @change.native="
                  getDepartmentoPaisList(
                    itemEducacionFormal.countryId,
                    'itemEducacionFormal'
                  )
                "
                required
                v-model="itemEducacionFormal.countryId"
                value-field="id"
                text-field="descripcion"
                :options="listaPaises"
              ></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Municipio</label
              >
              <b-form-select
                v-model="itemEducacionFormal.municipalityId"
                required
                value-field="id"
                text-field="descripcion"
                :options="itemEducacionFormal.MunicipioDepartamento"
              ></b-form-select>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Departamento</label
              >
              <b-form-select
                v-model="itemEducacionFormal.departamentId"
                required
                @change.native="
                  actualizaMunicipios(
                    itemEducacionFormal,
                    $event,
                    'itemEducacionFormal'
                  )
                "
                value-field="id"
                text-field="descripcion"
                :options="itemEducacionFormal.DepartamentosPais"
              ></b-form-select>
            </div>
          </div>
          <button
            type="submit"
            @click="getIdForData(itemEducacionFormal.id)"
            class="mx-1 my-4 btn px-4 py-2 btn-primary"
          >
            Guardar
          </button>
        </div>
      </form>
      <!-- nuevos -->
      <form @submit.prevent="createFormalEducation">
        <div v-show="isCreated">
          <p class="text-center">
            <b-badge class="mx-1" variant="success">Nueva</b-badge>Educación
            Formal
          </p>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Nivel Académico</label
              >
              <!-- <input required v-model="item.nombre" placeholder="Nombres y Apellidos" type="text" class="form-control" id="exampleFormControlInput1" /> -->
              <b-form-select
                required
                v-model="newEducacionFormal.academicLevelId"
                value-field="id"
                text-field="description"
                :options="NivelesAcademicos"
              ></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Institución Educativa</label
              >
              <input
                required
                v-model="newEducacionFormal.educationalInstitution"
                placeholder=""
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Título Obtenido</label
              >
              <input
                required
                v-model="newEducacionFormal.degree"
                placeholder="ej: Developer"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Fecha</label
              >
              <input
                required
                v-model="newEducacionFormal.endDate"
                type="date"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >País</label
              >
              <b-form-select
                required
                @change.native="
                  getDepartmentoPaisList(
                    newEducacionFormal.countryId,
                    'newEducacionFormal'
                  )
                "
                v-model="newEducacionFormal.countryId"
                value-field="id"
                text-field="descripcion"
                :options="listaPaises"
              ></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Municipio</label
              >
              <b-form-select
                required
                v-model="newEducacionFormal.municipalityId"
                value-field="id"
                text-field="descripcion"
                :options="dataLocation.municipios"
              ></b-form-select>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label"
                >Departamento</label
              >
              <b-form-select
                required
                v-model="newEducacionFormal.departamentId"
                @change.native="
                  actualizaMunicipios(
                    newEducacionFormal,
                    $event,
                    'newEducacionFormal'
                  )
                "
                value-field="id"
                text-field="descripcion"
                :options="dataLocation.departamentos"
              ></b-form-select>
            </div>
          </div>
          <div class="d-flex pt-2">
            <vs-button type="submit"> Guardar </vs-button>
            <vs-button danger @click="closeCreate"> Cancelar </vs-button>
          </div>
        </div>
      </form>
      <div class="col-xl-12 pt-3 text-right">
        <button
          ref="addNewFormal"
          v-show="!isCreated"
          @click="initDataInfoEducationFormal"
          class="mx-1 btn px-4 py-2 btn-success"
        >
          Agregar Nueva
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Number,
      required: true,
    },
    educacionFormal: {
      type: Array,
      required: true,
    },
    newEducacionFormal: {
      type: Object,
      required: true,
    },
    NivelesAcademicos: {
      type: Array,
      required: true,
    },
    listaPaises: {
      type: Array,
      required: true,
    },
    dataLocation: {
      type: Object,
      required: true,
    },
    isCreated: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    sendUpdateFormalEducation() {
      this.$emit("sendUpdateFormalEducation", this.educacionFormal);
    },
    deleteItem(itemId, endpoint) {
      this.$emit("deleteItem", itemId, endpoint);
    },
    createFormalEducation() {
      this.$emit("createFormalEducation", this.newEducacionFormal);
    },
    getIdForData(id) {
      this.$emit("getIdForData", id);
    },
    getDepartmentoPaisList(countryId, itemKey) {
      this.$emit("getDepartmentoPaisList", countryId, itemKey);
    },
    actualizaMunicipios(item, event, itemKey) {
      this.$emit("actualizaMunicipios", item, event, itemKey);
    },
    initDataInfoEducationFormal() {
      this.$emit("initDataInfoEducationFormal");
    },
    closeCreate() {
      this.$emit("closeCreate");
    },
  },
};
</script>

<style scoped></style>
